import './Label.css';

const Label = ({ value, unit, left, top }) => {

  return (
    <label
      style={{ top: `${top}%`, left: `${left}%` }}
      className="FloorplanLabel">
      <span className='value'>{value}</span>
      <span className='unit'>{unit}</span>
    </label>
  )
}

export default Label;