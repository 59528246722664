import './Label';
import './Floorplan.css';
import Label from './Label';
import WaterDetector from './WaterDetector';


function Floorplan({ data }) {
  if (!data || !data.sensorData) return (
    <div className="Floorplan">
      <img src={'./lovarts.svg'} alt="" />
    </div>
  )

  return (
    <div className="Floorplan">
      <img src={'./lovarts.svg'} align="top" alt="" />
      <WaterDetector
        detected={data.sensorData['Q3CB-L872-U4CY'].waterPresent}
        top={61.5}
        left={6.5}
      />
      <WaterDetector
        detected={data.sensorData['Q3CB-Z59L-HFW6'].waterPresent}
        top={61.5}
        left={39.5}
      />
      <WaterDetector
        detected={data.sensorData['Q3CB-QQ7S-HJU8'].waterPresent}
        top={78}
        left={39.5}
      />
      <Label
        value={Math.round(data.sensorData['Q3CA-FCVL-F64E'].temperature)}
        unit="°C"
        left={30}
        top={21} />
      <Label
        value={data.sensorData['Q3CA-FCVL-F64E'].humidity}
        unit="RH"
        left={47}
        top={21} />

      <Label
        value={Math.round(data.sensorData['Q3CA-SR2Y-MH75'].temperature)}
        unit="°C"
        left={30}
        top={53} />
      <Label
        value={data.sensorData['Q3CA-SR2Y-MH75'].humidity}
        unit="RH"
        left={47}
        top={53} />

      <Label
        value={Math.round(data.sensorData['Q3CA-J75M-79QU'].temperature)}
        unit="°C"
        left={30}
        top={92} />
      <Label
        value={data.sensorData['Q3CA-J75M-79QU'].humidity}
        unit="RH"
        left={47}
        top={92} />
    </div>
  )
}
export default Floorplan;
