import './Gauge.css'

const Gauge = ({ label, data, unit, round, size }) => {
  if (typeof data === "undefined" || data == null) return (<div className='Gauge'></div>);
  if (data > 99) data = 0
  if (round) {
    data = `${Math.round(data * 10) / 10}`;
    //if (windNow.indexOf(".") < 0) windNow = `${windNow}.0`
  }

  return (
    <div className={`Gauge Gauge_${size}`}>
      <span className='label'>{label}</span><br />
      <span className="data">{data}</span>
      <span className="unit">{unit}</span>
    </div>
  )
}

export default Gauge;