import { useEffect, useState } from 'react';
import { doc, getDoc } from "firebase/firestore";
import Floorplan from "../components/Floorplan";
import Gauge from '../components/Gauge';
import './Lovarts.css'

const Lovarts = ({ user, db }) => {
  let gettingData = false;
  const [data, setData] = useState({});

  const onSocketConnect = () => {
    console.log('socket connected')
  }

  const onSocketDisconnect = (reason) => {
    console.log('socket disconnected', reason)
  }

  const onSocketData = (dataStr) => {
    const data = JSON.parse(dataStr.data);
    //console.log('socket data', data.timestamp)
    setData(data);
  }

  useEffect(() => {
    let socket = null;
    if (!gettingData && user) {
      gettingData = true;
      getDoc(doc(db, 'conditions', 'now'))
        .then(d => d.data())
        .then(data => {
          console.log(data)
          setData(data);
          console.log('opening socket connection to', data.publicIp)
          socket = new WebSocket(`wss://lovartspi.overkligheten.com:3303`);
          socket.addEventListener('open', onSocketConnect);
          socket.addEventListener('close', onSocketDisconnect);
          socket.addEventListener('message', onSocketData);
        });
    }
    return () => {
      if (socket) {
        socket.removeEventListener('connect', onSocketConnect);
        socket.removeEventListener('disconnect', onSocketDisconnect);
        socket.removeEventListener('message', onSocketData);
      }
    }
  }, [user]);

  if (!data || !data.sensorData) return (<div className='App'></div>)
  const depthUnderKeel = data.depth.depth + data.depth.offset

  const updateDate = (new Date(data.timestamp)).toLocaleString('sv-SE');

  return (
    <div className="Lovarts">
      <div className='updateTime'>{updateDate}</div>
      <Gauge
        data={data.wind.speed.avg15}
        unit="m/s"
        label="Wind speed average (15 minutes)"
        round={true}
        size="l" />
      <Gauge
        data={data.wind.speed.min15}
        unit="m/s"
        label="Wind min (15)"
        round={true}
        size="s" />
      <Gauge
        data={data.wind.speed.now}
        unit="m/s"
        label="Wind speed now"
        round={true}
        size="s" />
      <Gauge
        data={data.wind.speed.max15}
        unit="m/s"
        label="Wind max (15)"
        round={true}
        size="s" />
      <hr />
      <Floorplan
        data={data} />
      <Gauge
        data={data.seaTemp}
        unit="°C"
        label="Sea temp"
        round={true}
        size="s" />
      <Gauge
        data={data.sensorData['Q3CG-QS9S-KUML'].airQuality}
        unit="%"
        label="Air Quality"
        round={true}
        size="s" />
      <Gauge
        data={data.sensorData['Q3CG-QS9S-KUML'].noise}
        unit="dBA"
        label="Ambient noise"
        round={true}
        size="s" />
      <Gauge
        data={data.sensorData['Q3CG-QS9S-KUML'].tvoc}
        unit="tvoc"
        label="Volatile particles"
        round={true}
        size="s" />
      <Gauge
        data={depthUnderKeel}
        unit="m"
        label="Depth under keel"
        round={true}
        size="s" />
    </div>)
}

export default Lovarts;