import { MaterialSymbol } from 'react-material-symbols';
import './WaterDetector.css';

const WaterDetector = ({ detected, top, left }) => {
  let icon = detected ? "humidity_high" : "format_color_reset";
  let color = detected ? "red" : "green";
  return (
    <label
      style={{ top: `${top}%`, left: `${left}%` }}
      className="FloorplanLabel">
      <MaterialSymbol icon={icon} size={24} fill grade={-25} color={color} />
    </label>
  )
}

export default WaterDetector;