import { useState } from 'react';
import './Signin.css';

const Signin = ({ onSignin, error }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const onSubmit = () => {
    onSignin(email, password)
  }

  return (
    <div className='Signin' onSubmit={onSubmit}>
      <div>
        <label>Email:</label>
        <input
          value={email}
          onChange={e => setEmail(e.target.value)}
          type="text" />
      </div>
      <div>
        <label>Password:</label>
        <input
          value={password}
          onChange={e => setPassword(e.target.value)}
          type="password" />
      </div>
      <input type="button"
        onClick={onSubmit} value="Sign in" />
      <br />
      {error}
    </div>
  )
}

export default Signin;