import { useEffect, useState } from 'react';
import { initializeApp } from "firebase/app";
import { getAuth, signInWithEmailAndPassword, onAuthStateChanged } from "firebase/auth";
import { doc, getDoc, getFirestore } from "firebase/firestore";
import Lovarts from './views/Lovarts';
import 'react-material-symbols/rounded';

import './App.css';
import Signin from './views/Signin';

const whitelist = [
  "marcus.leyman@gmail.com",
  "skalen@gmail.com",
  "simon.lessing@gmail.com"
]

function App() {
  const [currentUser, setCurrentUser] = useState(null);
  const [userDetermined, setUserDetermined] = useState(false);
  const [signinError, setSigninError] = useState("");
  // Your web app's Firebase configuration
  const firebaseConfig = {
    apiKey: "AIzaSyCCHrotdnTVAQv5HQw71ZY2btdCCQpmZHo",
    authDomain: "lovarts.overkligheten.com",
    projectId: "lovarts-ff063",
    storageBucket: "lovarts-ff063.appspot.com",
    messagingSenderId: "953791060128",
    appId: "1:953791060128:web:5adb00af3872dab8e3d1c5"
  };

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);
  const auth = getAuth(app);

  console.log(auth.currentUser)
  onAuthStateChanged(auth, (user) => {
    if (user) {
      const uid = user.uid;
      setCurrentUser(user);
      console.log(user.email)
    } else {
      console.log("no user")
    }
    setUserDetermined(true);
  });

  const validUser = () => {
    return currentUser != null && whitelist.indexOf(currentUser.email) >= 0;
  }
  const onSignin = (email, password) => {
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const user = userCredential.user;
        setCurrentUser(userCredential.user);
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        setSigninError(error.message);
        console.error('e', error);
      });
  }

  if (!userDetermined) {
    console.log('rendering spinner');
    return (
      <div className="App">
        <div className='Spinner'>
          <div className='lds-ripple'><div></div><div></div></div>
          <div>Loading...</div>
        </div>
      </div >
    );
  } else if (!validUser()) {
    return (
      <div className="App">
        <Signin
          onSignin={onSignin}
          error={signinError} />
      </div>
    );
  } else {
    return (
      <div className="App">
        <Lovarts
          user={currentUser}
          db={db} />
      </div>
    );
  }

}

export default App;
